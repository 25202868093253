import axios from 'axios';
import sfapi from '../src/Salesforce/api';
import {serialize} from "object-to-formdata";
import normalizePropertyTypeName from "../../src/helpers/normalizePropertyTypeName";
export default (data,advisor_id = null,listing = null) => {
  const p = new Promise(async (resolve, reject) => {

    const requestData = {
      // "name": "Scott Conrad",
      // "phone": "9418947325",
      // "email": "scott@honopu.com",
      // "scheduling_preference": "",
      // "comments": "Hey Rod, Lead developer here. Just checking something out. Please ignore.",
      // "source_url": "https://www.hawaiilife.com/agents?page=1&island=&query=Easterly",
      // "source_type": "Advisor Index Page",
      // "source_description": "",
      // "advisor_id": 41
    };

// LeadSource__c:'HL Web - Scheduled Showing',
//           Showing_Request_Date__c:'2021-5-05T16:20:00-1000',
    let debug = true;
    console.log("the request being passed in", data);




    let adv_id = data.advisor_id ? data.advisor_id : advisor_id;
    const req = {
      name: data.name,
      phone: data.phone,
      email: data.email,
      comments: data.comments,
      source_url: data.source_url,
      source_type: data.source_type,
      source_description: data.source_description,
      preapprove: data.preapprove,
      advisor_id: adv_id
    };
    //if(!advisor_id) advisor_id = 829; //Customer Experience User

    if (!req.name || !req.phone || !req.email) {
      reject("Missing required fields");
    }
    if (!req.comments) {
      reject("Missing comments");
    }
    const config = {
      headers: {
        "accept": "application/json",
        "cache-control": "no-cache",
        "content-type": "application/json;charset=UTF-8",
        "pragma": "no-cache",
      }
    };

    const d = req;
    let {agent_email} = data;
    if (advisor_id && !agent_email && req.advisor_id) {
      const agent = await axios.get(`/api/v3/search/agents/id/${req.advisor_id}`);
      agent_email = agent.data.email;
    }
    let first_name = data.name.split(' ')[0];
    let last_name = data.name.split(' ').slice(1).join(' ');
    if(!last_name) last_name = "Unknown"

    //console.log(data.listing);

    if(listing && !data.listing){
      apiData.listing = listing;
    }
    console.log(apiData);
    return;


    let apiData = {
      contact: {
        first_name: first_name,
        last_name: last_name,
        name: data.name,
        email: data.email,
        phone: data.phone
      },
      listing: data.listing ? data.listing : null,
      lead_source: data.lead_source,
      source_description: data.source_description,
      form: {
        comments: data.comments,
        preapprove: data.preapprove,
      },
      agent_email: agent_email,
    };

    const l = new sfapi();
    let agentEmail = null;

    l.action = apiData.lead_source === 'HL Web - Scheduled Showing' ? 'add_showing_request' : 'ask_question';
    l.setFirstName(apiData.contact.first_name);
    l.setLastName(apiData.contact.last_name);
    l.setEmail(apiData.contact.email);
    l.setPhone(apiData.contact.phone);
    l.setLeadSource(apiData.lead_source);
    //(apiData.source_description);
    //
    //l.setAgentEmail(apiData.listing.sell_agent_1_email);
    if (apiData.agent_email) {
      l.setAgentEmail(apiData.agent_email);
      agentEmail = apiData.agent_email
    } else {
      l.setAgentEmail(apiData.listing.listing_agent.email);
      agentEmail = apiData.listing.listing_agent.email;
    }
    l.setFormData(apiData.form);
    l.setListing(apiData.listing);
    l.setWebsource(data.source_url ? data.source_url : window.location.href.substring(0, window.location.href.indexOf('?')));
    try {
      l.create();
    } catch (error) {
      console.log('Error creating lead:', error);
    }

    if (data.preapprove) {
      l.setLeadSource('HL Web - Lending');
      apiData.form.comments = `Help me get pre-approved. \n \n` + apiData.form.comments;
      l.setFormData(apiData.form);
      await l.create();
    }

    let property_type_name = null;
    if(apiData.listing){
      property_type_name = apiData.listing.property_type_name;

    if(Array.isArray(property_type_name) && property_type_name.length){
      property_type_name = property_type_name[0];
    }
      property_type_name = normalizePropertyTypeName(property_type_name,apiData.listing.dwelling_type_name);
    }

    let dataF = {
      contact: {
        FirstName: apiData.contact.first_name,
        LastName: apiData.contact.last_name,
        Email: apiData.contact.email,
        Phone: apiData.contact.phone,
      },
      request: {
        Inquiry_Message__c: apiData.form.comments,
        LeadSource__c: data.lead_source ? data.lead_source : 'HL Web - Question',
        MLS_Number__c: apiData.listing ? apiData.listing.mls_id : null,
        Listing_Agent_Email__c: agentEmail,
        web_source__c: window.location.href.substr(0, 200),  //page url
        Neighborhood__c: apiData.listing ? apiData.listing.geo_town : null,
        Bedrooms_min__c:apiData.listing ? apiData.listing.total_beds : null,
        Region__c:apiData.listing ? apiData.listing.geo_island : null,
        Property_Price__c:apiData.listing ? apiData.listing.asking_price : null,
        District__c:apiData.listing ? apiData.listing.geo_district : null,
        pba__PropertyType__c: property_type_name, //Condo, Land, Residential, Commercial
      }
    }
    dataF.request.Inquiry_Message__c += "\n" + dataF.contact.Phone;
    if(!dataF.contact.LastName || !dataF.contact.LastName.length) dataF.contact.LastName = "Unknown";
    const fd = serialize(dataF);
    await axios.post('https://front-desk.propertybase.com/forms/2040d147d0f00f83022db26be3abbe33c9ad460a',
        fd, {headers: {'Content-Type': 'multipart/form-data'}});
    if(data.preapprove) {
      dataF.request.LeadSource__c = 'HL Web - Lending';
      dataF.request.Inquiry_Message__c = `I need help with pre-approval. \n \n`;
      await axios.post('https://front-desk.propertybase.com/forms/2040d147d0f00f83022db26be3abbe33c9ad460a',
          fd, {headers: {'Content-Type': 'multipart/form-data'}});
    }
    resolve(true);
  });
  return p;
}

