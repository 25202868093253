<script>
import formatPhone from "../helpers/formatPhoneDashes";
import axios from 'axios';
const {DateTime} = require('luxon');
import { VueDatePicker } from '../../web-components/node_modules/@mathieustan/vue-datepicker';
import CloseX from './close-x.vue';
import HLInput from '../components/HLInput';
export default {
  name: "ContactOptions",
  components:{
    Calendar:VueDatePicker,
    HLInput:HLInput,
    CloseX: CloseX
  },
  methods:{
    initEmbeddedMessaging() {
      console.log('We were able to init embedded messaging');
      try {
        const settings = { language: 'en_US' }; // For example, enter 'en' or 'en-US'

        embeddedservice_bootstrap.init(
          '00DU0000000KiDL',
          'Customer_Experience_Live_Message',
          'https://hawaiilife.my.site.com/ESWCustomerExperienceLi1722700683121',
          { hideChatButtonOnLoad: true, scrt2URL: 'https://hawaiilife.my.salesforce-scrt.com' }
        );
        console.log('Embedded messaging was initialized');
      } catch (err) {
        console.error('Error loading Embedded Messaging: ', err);
      }
    },
    setPhone(e){
      console.log(e)
      if(e && !e.target){
        this.phone = e;
      }
    },
    async scheduleCallback(){
      const date = new Date();
      const now = DateTime.fromJSDate(new Date(),{zone:'local'});
      const d = DateTime.fromJSDate(new Date(),{zone:'Pacific/Honolulu'});
      this.submitted = true;
      const hours = now.hour - d.hour;
      const minutes = now.minute - d.minute;
      let dateToCall = this.callDate;
      if(typeof dateToCall === 'string'){
        dateToCall = DateTime.fromFormat(this.callDate,'y-MM-dd').toJSDate();
        dateToCall.setHours(0,0);
      }
      //add
      const time = DateTime.fromJSDate(dateToCall);
      const time2 = time.set({hours:0,minutes:0});
      const hour = Math.floor(this.callTime / 60);
      const minute = this.callTime % 60;
      const theirtime = time2.plus({hour,minute});
      const change = time2.plus({hours: hour - hours,minutes: minute - minutes});
      this.showSuccess = true;
      this.customSuccessMessage = `We will call you at ${theirtime.toFormat('hh:mm a')} on ${theirtime.toFormat('M/d')}`;
      this.customSuccessMessage += ` - Hawaii Time: ${change.toFormat('M/d h:mm a')}`;
      const message = `Call them at ${theirtime.toFormat('hh:mm a')} on ${theirtime.toFormat('M/d')}- Hawaii Time: ${change.toFormat('M/d h:mm a')}`;
      const inquiryData =  {
        email:null,
        phone:this.phone,
        notes:message,
        contactReason:'Other',
        requestCallBack:1,
        callTimeHST:change,
        theirTime:theirtime,
        callback:true,
      };
      this.submitted = false;
      this.resetData();
      await this.sendInquiry(inquiryData);
    },
    async resetData(){
      this.phone = null;
      this.callDate = null;
      this.callTime = null;
      this.showRequestCallback = false;
      this.clickedCall = false;
      this.showSuccessCallBack = true;
    },

    async sendInquiry(sendData){
      const apiBase = `https://platform.hawaiilife.com/api/lead/create`;
      const {data} = await axios.post(apiBase,sendData);
      return data;
    },
    phoneNumberValidation(){
      if(this.phone) {
        const match = this.phone.match(/^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
        if(match) return true;
      }
      if(!this.phone) return false;
      return false;
    },
    showTime(minutes){
      const now =  DateTime.fromJSDate(new Date());
      const selected = DateTime.fromJSDate(this.callDate);
      if(selected.diffNow().days === 0){
        const minutesNow = (now.hour * 60) + now.minute;
        if(minutesNow + 30 > minutes){
          return false;
        }
      }
      //todo, compare

      return true;
    },
    formatPhone,
    showAgentEmailModal(){
      this.$store.dispatch('trackEvent','EmailAgent');
      if(this.useContactModalExternal){
        this.showContactModalExternal(this.agentOrSlugAgent);
        return;
      }
      this.$store.commit('ui/setShowAgentEmailModal',this.agentOrSlugAgent);
    },
    openAgentUrl(){
      this.$store.dispatch('trackEvent','AgentProfile');
      window.location = this.agentOrSlugAgent.root_path ? "https://www.hawaiilife.com/" + this.agentOrSlugAgent.root_path + '?back_to_search=1' : 'https://www.hawaiilife.com/' + this.agentOrSlugAgent.path + '?back_to_search=1';
    },
    mounted(){
      window.HIDE_SHOW_LOADING_LIVE_CHAT = () => {
        this.showLoadingLiveChat = false;
      }
    },
    startLiveChat(){

      //the legacy one
      if(window.embedded_svc && window.embedded_svc.bootstrapEmbeddedService) {
        window.embedded_svc.bootstrapEmbeddedService();
        document.querySelector('.embeddedServiceHelpButton').click();
        setTimeout(() => {
          this.showLoadingLiveChat = false;
          this.hideContactOverlay();
        }, 2000);
        return;
      }



      if(!embeddedservice_bootstrap.utilAPI) return;
      embeddedservice_bootstrap.utilAPI
        .launchChat()
        .then(function (success) {
          // Add actions to run after the chat client launches successfully.
        });
      this.hideContactOverlay();
      return;
      //this.$store.commit('ui/setShowLiveChat',true);
      this.$store.dispatch('trackEvent','liveChat');

      this.showLoadingLiveChat = true;
        window
        document.querySelector('.embeddedServiceHelpButton').click();
        setTimeout(() => {
          this.showLoadingLiveChat = false;
          this.hideContactOverlay();
        }, 1000);
    },
    emailHawaiiLife() {
      this.$store.dispatch('trackEvent','EmailHawaiiLife');
      if(this.useContactModalExternal){
        this.showContactModalExternal('hawaiilife');
        return;
      }

      this.$store.commit('ui/setShowAgentEmailModal', {
        email: 'hello@hawaiilife.com',
        user_name: 'Hawaii Life - Customer Experience',
        designation: '800-370-3848 | hello@hawaiilife.com',
      });
    }
  },
  data(){
    return {
      showLoadingLiveChat:false,
      showRequestCallback: false,
      clickedCall:false,
      minCallBackDate:new Date(),
      callDate:null,
      callTime:null,
      phone:null,
      timeError:false,
      submitted:false,
      showSuccessCallBack: false,
      customSuccessMessage:null,
      times:{
        480:'8am',
        510:'8:30am',
        540:'9am',
        570:'9:30am',
        600:'10am',
        630:'10:30am',
        660:'11am',
        690:'11:30am',
        720:'12pm',
        750:'12:30pm',
        780:'1pm',
        810:'1:30pm',
        840:'2pm',
        870:'2:30pm',
        900:'3pm',
        930:'3:30pm',
        960:'4pm',
        990:'4:30pm',
        1020:'5pm',
        1050:'5:30pm',
        1080:'6pm',
        1110:'6:30pm',
        1140:'7pm',
        1170:'7:30pm',
        1200:'8pm',
        1230:'8:30pm',
        1260:'9pm',
        1290:'9:30pm',
        1320:'10pm',
      }
    }
  },
  props: {
    showContactOverlay: {
      type: Boolean,
      default: false
    },
    agentOrSlugAgent: {
      type: Object,
      default: null
    },
    agent: {
      type: Object,
      default: null
    },
    agentDesignation: {
      type: String,
      default: ''
    },
    showContactModalExternal: {
      type: Function,
      default: () => {
      }
    },
    agentFirstName: {
      type: String,
      default: ''
    },
    useContactModalExternal: {
      type: Boolean,
      default: false
    },
    hideContactOverlay: {
      type: Function,
      default: () => {
        console.warn("hideContactOverlay function not passed to ContactOptions component");
      }
    }
  }
}
</script>

<template>
  <div class="contact-options" @click.stop="() => {}">
    <div v-if="showLoadingLiveChat" class="showing-live-chat">
      <h2>
        We're loading live chat..
      </h2>
    </div>


    <div class="agent-contact-wrapper" v-if="agentOrSlugAgent">
      <div class="agent-options-title">
        <span @click="openAgentUrl" class="afs">{{agentFirstName}}</span> <span class="small-grey-text">{{agentDesignation}}</span>
      </div>
      <a :href="'tel:' + agentOrSlugAgent.phone" class="agent-contact"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 12.5C15.8 12.5 14.5 12.3 13.4 11.9C13.3 11.9 13.2 11.9 13.1 11.9C12.8 11.9 12.6 12 12.4 12.2L10.2 14.4C7.4 12.9 5 10.6 3.6 7.8L5.8 5.6C6.1 5.3 6.2 4.9 6 4.6C5.7 3.5 5.5 2.2 5.5 1C5.5 0.5 5 0 4.5 0H1C0.5 0 0 0.5 0 1C0 10.4 7.6 18 17 18C17.5 18 18 17.5 18 17V13.5C18 13 17.5 12.5 17 12.5ZM2 2H3.5C3.6 2.9 3.8 3.8 4 4.6L2.8 5.8C2.4 4.6 2.1 3.3 2 2ZM16 16C14.7 15.9 13.4 15.6 12.2 15.2L13.4 14C14.2 14.2 15.1 14.4 16 14.4V16Z" fill="#262626"/>
      </svg>
        Call <span>{{formatPhone(agentOrSlugAgent.phone)}}</span></a>
      <a :href="'sms:' + agentOrSlugAgent.phone" class="agent-contact">
        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 0C4.5 0 0 3.58 0 8C0.05 10.15 1.06 12.17 2.75 13.5C2.75 14.1 2.33 15.67 0 18C2.37 17.89 4.64 17 6.47 15.5C7.61 15.83 8.81 16 10 16C15.5 16 20 12.42 20 8C20 3.58 15.5 0 10 0ZM10 14C5.58 14 2 11.31 2 8C2 4.69 5.58 2 10 2C14.42 2 18 4.69 18 8C18 11.31 14.42 14 10 14ZM15 9V7H13V9H15ZM11 9V7H9V9H11ZM7 9V7H5V9H7Z" fill="#262626"/>
        </svg>
        Text <span>{{formatPhone(agentOrSlugAgent.phone)}}</span>
      </a>
      <a class="agent-contact" @click="showAgentEmailModal">  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 2C20 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2ZM18 2L10 7L2 2H18ZM18 14H2V4L10 9L18 4V14Z" fill="#262626"/>
      </svg>
        Email <span class="regcase">{{agentOrSlugAgent.email}}</span></a>
    </div>
    <div class="company-options" :class="{white:!agentOrSlugAgent,'blue-spans':!agentOrSlugAgent}">
      <div class="company-options-title" v-if="agentOrSlugAgent">Hawaii Life</div>
      <a @click="clickedCall = !clickedCall" class="agent-contact"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 12.5C15.8 12.5 14.5 12.3 13.4 11.9C13.3 11.9 13.2 11.9 13.1 11.9C12.8 11.9 12.6 12 12.4 12.2L10.2 14.4C7.4 12.9 5 10.6 3.6 7.8L5.8 5.6C6.1 5.3 6.2 4.9 6 4.6C5.7 3.5 5.5 2.2 5.5 1C5.5 0.5 5 0 4.5 0H1C0.5 0 0 0.5 0 1C0 10.4 7.6 18 17 18C17.5 18 18 17.5 18 17V13.5C18 13 17.5 12.5 17 12.5ZM2 2H3.5C3.6 2.9 3.8 3.8 4 4.6L2.8 5.8C2.4 4.6 2.1 3.3 2 2ZM16 16C14.7 15.9 13.4 15.6 12.2 15.2L13.4 14C14.2 14.2 15.1 14.4 16 14.4V16Z" fill="#262626"/>
      </svg>
        Call <span>{{`800-370-3848`}}</span></a>
      <div class="call-or-schedule-here slide-fade-in" v-if="clickedCall">
        <div class="call-or-schedule-row" v-if="!showRequestCallback">
    <a class="hl-btn blue" href="tel:800-370-3848">Call Now</a>
          <div class="or">Or</div>
          <div @click="showRequestCallback = true;" class="schedule-callback-text"><span class="underline-dots">Schedule </span> callback from us <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 96 96" xml:space="preserve" style="width: 25px;"><g id="XMLID_2_"><path id="XMLID_19_" d="M24.7,73.4h9.5v-9.5h-9.5V73.4z M36.4,73.4h10.6v-9.5H36.4V73.4z M24.7,61.8h9.5V51.2h-9.5
		V61.8z M36.4,61.8h10.6V51.2H36.4V61.8z M24.7,49.1h9.5v-9.5h-9.5V49.1z M49.1,73.4h10.6v-9.5H49.1V73.4z M36.4,49.1h10.6v-9.5
		H36.4V49.1z M61.8,73.4h9.5v-9.5h-9.5V73.4z M49.1,61.8h10.6V51.2H49.1V61.8z M37.4,33.2v-9.5c0-0.3-0.1-0.5-0.3-0.7
		c-0.2-0.2-0.5-0.3-0.7-0.3h-2.1c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.5-0.3,0.7v9.5c0,0.3,0.1,0.5,0.3,0.7s0.5,0.3,0.7,0.3h2.1
		c0.3,0,0.5-0.1,0.7-0.3C37.3,33.7,37.4,33.5,37.4,33.2z M61.8,61.8h9.5V51.2h-9.5V61.8z M49.1,49.1h10.6v-9.5H49.1V49.1z
		 M61.8,49.1h9.5v-9.5h-9.5V49.1z M62.8,33.2v-9.5c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.5-0.3-0.7-0.3h-2.1c-0.3,0-0.5,0.1-0.7,0.3
		c-0.2,0.2-0.3,0.5-0.3,0.7v9.5c0,0.3,0.1,0.5,0.3,0.7s0.5,0.3,0.7,0.3h2.1c0.3,0,0.5-0.1,0.7-0.3S62.8,33.5,62.8,33.2z M75.5,31.1
		v42.3c0,1.1-0.4,2.1-1.3,3s-1.8,1.3-3,1.3H24.7c-1.1,0-2.1-0.4-3-1.3c-0.8-0.8-1.3-1.8-1.3-3V31.1c0-1.1,0.4-2.1,1.3-3
		c0.8-0.8,1.8-1.3,3-1.3H29v-3.2c0-1.5,0.5-2.7,1.6-3.7c1-1,2.3-1.6,3.7-1.6h2.1c1.5,0,2.7,0.5,3.7,1.6c1,1,1.6,2.3,1.6,3.7v3.2
		h12.7v-3.2c0-1.5,0.5-2.7,1.6-3.7c1-1,2.3-1.6,3.7-1.6h2.1c1.5,0,2.7,0.5,3.7,1.6c1,1,1.6,2.3,1.6,3.7v3.2h4.2c1.1,0,2.1,0.4,3,1.3
		C75.1,28.9,75.5,29.9,75.5,31.1z" class="st0" fill="black"></path></g></svg></div>
        </div>
        <div class="request-callback-wrapper slide-fade-in" v-if="showRequestCallback">
          <div class="tr"><CloseX :close="() => { showRequestCallback = false;}"></CloseX></div>
          <div>
            <span>Schedule a call back.</span>
            <calendar :placeholder="'Choose a future date, or today'" :zIndex="5005" v-model="callDate"  :minDate="minCallBackDate" format="ddd, MMM D" color="#222222" :no-header="true"></calendar>
          </div>
          <div>
            <select class="hl-select" :class="{error:timeError && submitted}" v-model="callTime" style="margin-top:10px;">
              <option :value="null" disabled>What time?</option>
              <option v-for="(time,idx) in times" v-if="showTime(idx)" :value="idx">{{time}}</option>
            </select>
          </div>
          <div>
            <HLInput :value="phone" :additional-class="'wide'" :validate-fn="phoneNumberValidation" :label="'Phone Number'" :set-value="setPhone"></HLInput>
          </div>
          <div v-if="phoneNumberValidation(phone) && callDate && callTime">
            <a @click="scheduleCallback" class="hl-btn external-hl-btn">Submit</a>
          </div>
        </div>
      </div>
      <div v-if="showSuccessCallBack" @click="showSuccessCallBack = false;" class="slide-fade-in white-bg success-message-item">
        {{customSuccessMessage ? customSuccessMessage : 'We will call you at your designated time and phone number. Mahalo.'}}
      </div>
      <a href="sms:+18003703848" class="agent-contact">
        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 0C4.5 0 0 3.58 0 8C0.05 10.15 1.06 12.17 2.75 13.5C2.75 14.1 2.33 15.67 0 18C2.37 17.89 4.64 17 6.47 15.5C7.61 15.83 8.81 16 10 16C15.5 16 20 12.42 20 8C20 3.58 15.5 0 10 0ZM10 14C5.58 14 2 11.31 2 8C2 4.69 5.58 2 10 2C14.42 2 18 4.69 18 8C18 11.31 14.42 14 10 14ZM15 9V7H13V9H15ZM11 9V7H9V9H11ZM7 9V7H5V9H7Z" fill="#262626"/>
        </svg>
        Text <span>{{`800-370-3848`}}</span>
      </a>
      <a class="agent-contact" @click="emailHawaiiLife">  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 2C20 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2ZM18 2L10 7L2 2H18ZM18 14H2V4L10 9L18 4V14Z" fill="#262626"/>
      </svg>
        Email <span class="regcase">hello@hawaiilife.com</span></a>
      <a @click.stop="startLiveChat" class="agent-contact"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 2V9H3.17L2 10.17V2H13ZM14 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V15L4 11H14C14.2652 11 14.5196 10.8946 14.7071 10.7071C14.8946 10.5196 15 10.2652 15 10V1C15 0.734784 14.8946 0.48043 14.7071 0.292893C14.5196 0.105357 14.2652 0 14 0ZM19 4H17V13H4V15C4 15.2652 4.10536 15.5196 4.29289 15.7071C4.48043 15.8946 4.73478 16 5 16H16L20 20V5C20 4.73478 19.8946 4.48043 19.7071 4.29289C19.5196 4.10536 19.2652 4 19 4Z" fill="#262626"/>
      </svg>
        Live Chat</a>
    </div>


  </div>
</template>

<style scoped lang="scss">
@import '../assets/scss/variables/default';
@import '../assets/scss/variables/media';
@import '../assets/scss/mixins/box-shadow';
.contact-options{
  position:fixed;
  top:67px;
  width:100vw;
  left:0;
  @include box-shadow-small;
  display:flex;
  flex-direction: column;
  background-color: white;
  z-index:5050;
  @media(min-width: $media-mobile){
    position:absolute;
    top:50px;
    left:0px;
    z-index:300;
    width:400px;
    color:black;
    z-index:5050;
  }

  @media(min-width: $media-mobile) and (max-width: 1100px){
    position:absolute;
    top:50px;
    left:-280px;
    z-index:300;
  }

  .agent-contact-wrapper{
    padding:20px;
    padding-bottom:5px;
    a.agent-contact{
      display:block;
      text-decoration: none;
      box-sizing: border-box;
      font-family: "HL-Whitney-Bold", sans-serif;
      width:100%;
      color:black;
      &:hover{
        cursor:pointer;
        background-color: #f8f8f8;
      }
      font-family: HL-Whitney-Bold;
      text-transform: uppercase;
      font-size:16px;
      padding:15px;
      border-bottom:1px #f8f8f8 solid;
      &:last-child{
        border-bottom:none;
      }

    }
  }
  svg{
    margin-right:10px;
  }
  .company-options-title,.agent-options-title{
    @include small-text;
    font-weight:300;
    text-transform: none;
    padding-left:10px;
    color:#333333;
    font-size:13px;

  }
  .company-options-title{
    padding-top:10px;
  }
  .agent-options{
    font-family: HL-Whitney-Bold,sans-serif;
  }

  .company-options{
    &:hover{
      cursor:pointer;
    }
    @include box-shadow-small;
    font-family: HL-Whitney-Bold,sans-serif;
    text-transform: uppercase;
    font-size:16px;
    background-color: $grey-100;
    &.white{
      background-color: white;
    }
    padding:20px;
    padding-bottom:0px;
    padding-top:0px;
    a{
      text-decoration: none;
      display:block;
      color:black;
      padding:15px;
      border-bottom:1px $grey-300 solid;
      span{
        padding-left:5px;
        &.regcase{
          text-transform: lowercase;
        }
      }
      &:last-child{
        border-bottom:none;
      }
    }
    &.blue-spans{
      span{
        margin-left:5px;
        color:#12b5ea;
        text-transform: none;
      }
    }

  }
  .agent-contact-wrapper{
    .agent-contact{
      span{
        color:#12b5ea;
        padding-left:5px;
        &.regcase{
          text-transform: lowercase;
        }
      }
    }
  }

  .afs{
    font-size:17px;
    font-family:HL-Whitney-Bold,sans-serif;
    color:#12b5ea;
    &:hover{
      cursor:pointer;
      text-decoration: underline;
    }
  }

  .showing-live-chat{
    display:flex;
    justify-content:center;
    align-items:center;
    position:fixed;
    top:0;
    left:0;
    width:100svw;
    height:100svh;
    background-color: rgba(255, 255, 255, 0.8);
    display:flex;
    z-index:5000;
    align-content: center;
    vertical-align: center;
    h2{
      font-size:24px;
      font-family:HL-Whitney-Bold,sans-serif;
      color:#333333;
    }
  }

  .call-or-schedule-here{
    padding:10px;
    div.call-or-schedule-row{
      display:flex;
      flex-direction:column;
      justify-content: center;
      padding:10px;
      div{
        justify-content: center;
        text-align: center;
      }
    }
    background-color: white;
    div.btn{
      background-color:#12b5ea;
      padding:10px 20px;
      color:white;
    }
    div.or{
      background-color:transparent;
      font-family:HL-Whitney-Book,sans-serif;
      color:#333;
      font-size:13px;
      padding:10px;
    }
    svg path{
      fill:black;
    }
    .schedule-callback-text{
      font-size:17px;
      font-family:HL-Whitney-Book,sans-serif;
      text-transform: none;
      color:#333;
      .underline-dots{
        border-bottom:1px dashed #12b5ea;
        padding-bottom:1px;
        color:#12b5ea;
      }


    }
  }
a.agent-contact{
  font-family:HL-Whitney-Bold,sans-serif;
}
}
div.datepicker__wrapper {
  padding: 15px !important;
  height: 40px !important;
  min-height: 40px !important;
  border:1px #f3f3f3 solid;
  padding-top:60px;
  input[type="text"] {
    outline:1px red solid;
  }

  .datepicker__input {
    border: 1px #f3f3f3 solid;
    border-radius: 0;
    color: #222222 !important;
  }
}

.company-options .request-callback-wrapper div{
 font-family:HL-Whitney-Bold,sans-serif;
 text-transform: none;
  div{
    margin-top:5px;
    margin-bottom:5px;
  }
}
.request-callback-wrapper{
  position:relative;
  .tr{
    position:absolute;
    top:-22px;
    right:-10px;
  }
  div{
    margin-bottom:10px;
  }
}

/* Initial hidden state and animation */
@keyframes slideFadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-fade-in {
  animation: slideFadeIn 0.6s ease-out forwards;
}

.white-bg.success-message-item{
  background-color: white;
  padding:10px;
  font-family:'HL-Whitney-Book', sans-serif;
  font-weight:normal;
  font-size:16px;
  text-transform: none;
  color:black;
}

a.external-hl-btn{
  font-family: HL-Whitney-Bold, sans-serif;
  display:block;
  background-color:#12b5ea;
  padding:10px 20px;
  color:white !important;
  font-size:20px;
  text-align: center;
  text-transform: uppercase;
  &:hover{
    background-color: #4b3900;
  }
}

a.hl-btn.blue{
  background-color: #12b5ea;
  color: white !important;
  font-family: HL-Whitney-Bold, sans-serif;
  text-align: center;
  &:hover{
    background-color: #4b3900;
  }
}

</style>
